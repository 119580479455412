<template>
  <div class="d-flex">
    <div v-if="booking.fromRecurrence">
      <div>
      <span>
        <d-button
          :class="innerWidth ? 'ml-4 mr-4' : 'custom-customer-button'"
          text="general.actions.delete"
          class="d-btn-sm btn d-btn-danger font-text-title "
          icon="fa fa-angle-down mr-1"
          icon-position="right"
          @on:button-click="show = !show"
        />
        <d-dropdown-form
          :show="show"
          :right="true"
          :identifier="`identifier-${booking.id}`"
          padding="10px"
          min-width="120px"
        >
      <template v-slot:dropdownbutton>
      </template>
      <template v-slot:dropdownbody>
        <b-dropdown-item
            @click="deleteBooking"
          >
          {{ $t('views.bookings.index.dropdown.delete-this') }}
        </b-dropdown-item>
        <b-dropdown-divider/>
        <b-dropdown-item
          @click="deleteBooking('followings')"
        >
          {{ $t('views.bookings.index.dropdown.delete-recurrent-followings') }}
        </b-dropdown-item>
        <b-dropdown-divider/>
        <b-dropdown-item
          @click="deleteBooking('pending')"
        >
          {{ $t('views.bookings.index.dropdown.delete-recurrent-pending') }}
        </b-dropdown-item>
      </template>
    </d-dropdown-form>
      </span>
      </div>
    </div>
    <div v-else>
      <d-button
        :class="innerWidth ? 'ml-4 mr-4' : 'custom-customer-button'"
        text="general.actions.delete"
        class="d-btn-sm btn d-btn-danger font-text-title "
        @on:button-click="deleteBooking"
      />
    </div>
    <d-button
      text="general.actions.update"
      class="d-btn-sm font-text-title d-btn btn d-btn-primary-new"
      :class="innerWidth ? 'mr-4' : 'ml-0 mr-0 custom-customer-button'"
      :disabled="isBusy ? 'disabled' : ''"
      @on:button-click="$emit('on:booking:update')"
    />
    <confirm-modal
      :data="modal"
      @on:booking:cancel="onCancel"
      @on:booking:confirm="onConfirm"
    />
  </div>
</template>
<script>
import {deleteBooking} from "@api/doinsport/services/bookings/booking.api";
import {SUCCESS} from "@plugins/flash";

export default {
  data: () => ({
    modal: {
      hide: false,
      display: false,
      target: 'booking',
      text: 'confirm_delete_booking',
      title: 'delete_booking.title_toast',
    },
    dataModal: null,
    show: false,
  }),
  components: {
    ConfirmModal: () => import("@views/planning/schedule/playgrounds/bookings/details/footer-actions/ConfirmModal")
  },
  props: ['innerWidth', 'isBusy', 'booking'],
  methods: {
    deleteBooking(extendsTo = null) {
      this.modal.display = !this.modal.display;

      const data = {canceled: true};

      if (['pending', 'followings'].includes(extendsTo)) data.extendsTo = extendsTo;

      this.dataModal = data;
    },
    onConfirm() {
      deleteBooking(this.booking.id, this.dataModal)
        .then(res => {
          if (res) {
            this.$flash(this.$t('delete_booking.title_toast'), this.$t('delete_booking.success_content'), 3000, SUCCESS);

            this.$bus.$emit('on:booking-canceled', this.booking);
            this.$emit('hidden');
          }
        })
        .finally(() => {
          document.body.setAttribute('style', 'overflow-y:scroll;');
          this.hide();
        })
      ;
    },
    onCancel() {
      this.dataModal = null;
      this.hide();
    },
    hide() {
      this.modal.hide = !this.modal.hide;
    }
  }
}
</script>
<style scoped lang="scss">
@import "@lazy/_dropdown";
@import "@lazy/bookings/footer-actions/_booking-actions";

/deep/ .dropup .dropdown-menu {
  bottom: 33px;
}
</style>
